export const isProduction = process.env.NODE_ENV !== 'development';

const appConfig = {
  spotifyClientId: '2fbbae7fa1694b40a66a5f60e1922120',
  callbackUri: isProduction ? 'https://spotify.scottrupprecht.com' : 'http://localhost:3000',
  baseUrl: isProduction ? 'https://scottrupprecht.azurewebsites.net' : 'https://localhost:44357',
  scopes: ['user-top-read'].join(' '),
};

export default appConfig;
