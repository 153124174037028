import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import qs from 'qs';
import appConfig from './appConfig';
import SpotifyWebApi from 'spotify-web-api-node';
import { Button, Col, Container, Row } from 'reactstrap';

const spotifyWebApi = new SpotifyWebApi();

interface TopArtistState {
  shortTerm: string[];
  mediumTerm: string[];
  longTerm: string[];
}

interface TopTrackState {
  shortTerm: { name: string; artist: string }[];
  mediumTerm: { name: string; artist: string }[];
  longTerm: { name: string; artist: string }[];
}

const App = () => {
  const [me, setMe] = useState<string | undefined>();
  const [topArtists, setTopArtists] = useState<TopArtistState>();
  const [topTracks, setTopTracks] = useState<TopTrackState>();
  const { access_token: accessToken } = qs.parse(window.location.hash.substr(1)) as { access_token?: string };

  useEffect(() => {
    if(accessToken) {
      spotifyWebApi.setAccessToken(accessToken);
      (async () => {
        const me = await spotifyWebApi.getMe();
        setMe(me.body.id)
        loadTopArtists();
        loadTopTracks();
      })();

      return;
    }
    window.location.href = `https://accounts.spotify.com/authorize?client_id=${appConfig.spotifyClientId}&redirect_uri=${encodeURI(appConfig.callbackUri)}&response_type=token&scope=${appConfig.scopes}`;
  }, [accessToken]);

  function loadTopArtists() {
    (async () => {
      const shortTerm = await spotifyWebApi.getMyTopArtists({
        time_range: 'short_term'
      });
      const mediumTerm = await spotifyWebApi.getMyTopArtists({
        time_range: 'medium_term'
      });
      const longTerm = await spotifyWebApi.getMyTopArtists({
        time_range: 'long_term'
      });
      setTopArtists({
        shortTerm: shortTerm.body.items.map(i => i.name),
        mediumTerm: mediumTerm.body.items.map(i => i.name),
        longTerm: longTerm.body.items.map(i => i.name),
      })
    })();
  }

  function loadTopTracks() {
    (async () => {
      const shortTerm = await spotifyWebApi.getMyTopTracks({
        time_range: 'short_term'
      });
      const mediumTerm = await spotifyWebApi.getMyTopTracks({
        time_range: 'medium_term'
      });
      const longTerm = await spotifyWebApi.getMyTopTracks({
        time_range: 'long_term'
      });
      setTopTracks({
        shortTerm: shortTerm.body.items.map(i => ({ name: i.name, artist: i.artists.map(a => a.name).join(', ') })),
        mediumTerm: mediumTerm.body.items.map(i => ({ name: i.name, artist: i.artists.map(a => a.name).join(', ') })),
        longTerm: longTerm.body.items.map(i => ({ name: i.name, artist: i.artists.map(a => a.name).join(', ') })),
      })
    })();
  }

  function saveToDatabase() {
    (async () => {
      toast.info('Starting save...');
      const res = await fetch(`${appConfig.baseUrl}/api/spotifyTopTracker/snapshot`, {
        method: 'POST',
        // @ts-ignore
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-Spotify-Username': me
        },
        body: JSON.stringify({ topTracks, topArtists })
      });

      toast.dismiss();

      if(!res.ok) {
        toast.error('Failed.');
        return;
      }

      toast.success('Successfully saved');
    })();
  }

  return (
    <>
      {/* Navigation*/}
      <nav
        className="navbar navbar-expand-lg bg-secondary text-uppercase"
        id="mainNav"
      >
        <div className="container">
          <a className="navbar-brand" href="#page-top">
            Spotify Top Tracker ({me})
          </a>
        </div>
      </nav>
      <div className="fill overflow-auto">
        <Container>
          <Row>
            <Col md={6}>
              <h3 className="text-center">Top Artists</h3>
              <h6 className="text-center mt-1">Short-Term</h6>
              {
                topArtists?.shortTerm.map(t => (
                  <div key={t} className="text-center">{t}</div>
                ))
              }
              <hr />
              <h6 className="text-center mt-4">Medium-Term</h6>
              {
                topArtists?.mediumTerm.map(t => (
                  <div key={t} className="text-center">{t}</div>
                ))
              }
              <hr />

              <h6 className="text-center mt-4">Long-Term</h6>
              {
                topArtists?.longTerm.map(t => (
                  <div key={t} className="text-center">{t}</div>
                ))
              }
            </Col>
            <Col md={6}>
              <h3 className="text-center">Top Tracks</h3>
              <h6 className="text-center mt-1">Short-Term</h6>
              {
                topTracks?.shortTerm.map(t => (
                  <div key={t.name} className="text-center">{t.name} <small>{t.artist}</small></div>
                ))
              }
              <hr />
              <h6 className="text-center mt-4">Medium-Term</h6>
              {
                topTracks?.mediumTerm.map(t => (
                  <div key={t.name} className="text-center">{t.name} <small>{t.artist}</small></div>
                ))
              }
              <hr />

              <h6 className="text-center mt-4">Long-Term</h6>
              {
                topTracks?.longTerm.map(t => (
                  <div key={t.name} className="text-center">{t.name} <small>{t.artist}</small></div>
                ))
              }
            </Col>
          </Row>
        </Container>
      </div>

      <footer
        className="bg-secondary text-uppercase py-2"
      >
        <div className="text-center">
          <Button color="primary" onClick={saveToDatabase}>Save to Database</Button>
        </div>
      </footer>

      <ToastContainer />
    </>

  );
};

export default App;
